/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 110번째 로그에서는 Elasticsearch 라이센스, 하이퍼커넥트 인수, okta에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "Elasticsearch 오픈소스 라이센스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1533"
  }, "Elastic과 AWS의 분쟁, 어떻게 봐야 할까?")), "\n"), "\n", React.createElement(_components.h3, null, "하이퍼커넥트 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23990482"
  }, "K-데이팅앱 아자르 2조 대박, 미국 틴더에 매각 - 중앙일보")), "\n"), "\n", React.createElement(_components.h3, null, "okta"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.okta.com/"
  }, "Okta | Identity for the internet")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
